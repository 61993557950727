<template>
  <template v-if="isComponent && isUzi(tagName)">
    <span v-if="attr.preloaded">
      <UziQuery v-bind="attr"></UziQuery>
    </span>
    <v-lazy v-else :tag="attr?.format?.disptype === 'csv' ? 'span' : 'div'"><UziQuery v-bind="attr"></UziQuery></v-lazy>
  </template>
  <template v-else-if="isComponent && tagName === 'MainBanner'">
    <ImageBanner v-bind="attr"></ImageBanner>
  </template>
  <template v-else-if="tagName === 'img'">
    <img v-bind="attr" />
  </template>
  <template v-else-if="tagName === 'source'">
    <source v-bind="attr" />
  </template>
  <template v-else-if="tagName === 'nuxt-link'">
    <NuxtLink v-bind="attr" aria-label="link">
      <template v-for="(child, ntx) in childNodes.filter(emptyNodes)" :key="ntx"> <Element v-bind="child" /> </template>
    </NuxtLink>
  </template>
  <component :is="comp" v-else v-bind="mkAttr(tagName, rawText, attr, isComponent)">
    <template v-for="(child, ntx) in childNodes.filter(emptyNodes)" :key="ntx">
      <Element v-bind="child" />
    </template>
  </component>
</template>

<script setup>
import { isUzi } from "@/lib/tools.js";
import Dumb from "@/components/Dumb";
import RawText from "@/components/RawText";
defineOptions({ inheritAttrs: true });

const props = defineProps({
  tagName: { type: String, required: false, default: "RawText" },
  rawText: { type: String, required: false, default: "" },
  isComponent: { type: Boolean, required: false, default: false },
  attr: { type: Object, required: false, default: () => {} },
  childNodes: { type: Array, required: false, default: () => [] },
});
const { isComponent, tagName } = toRefs(props);
const instance = getCurrentInstance();
const page = inject("page");

const emptyNodes = e => e && (e.tagName || e.rawText);
const mkAttr = (tag, rawText, attr, isCom) =>
  tag === "RawText"
    ? { rawText }
    : {
        ...(attr || {}),
        ...(isCom && !instance.appContext.components[tag] ? { tagName: tag } : {}),
      };

const resolveDynamicComp = tag => instance.appContext.components[tag] || Dumb;
// const hydrotest = (unref(page).tags || []).includes("hydrotest");
const comp =
  props.tagName === "RawText" ? RawText : isComponent.value ? resolveDynamicComp(tagName.value) : tagName.value;
</script>
