<template>
  <div>
    <ViewCard v-if="result && view === 'card'" :data="result" :is-grid="isGgrid"></ViewCard>
    <ViewList v-else-if="result && view === 'list'" :data="result"></ViewList>
    <ViewTable v-else-if="result && view === 'table'" :data="result"></ViewTable>
    <ViewCsv v-else-if="result && view === 'csv'" :data="result"></ViewCsv>
    <div v-if="['card', 'list', 'table'].includes(view) && seeMore !== 'none'" align="right">
      <v-btn
        variant="text"
        color="linkColor"
        class="text-lowercase font-weight-regular see-more"
        @click="seeMore === 'more' ? showMore() : showLess()"
      >
        {{ seeMore }}
      </v-btn>
    </div>
  </div>
</template>

<script setup>
import { UziQuery } from "@/apollo/queries/UziQuery.gql";
import { queryFixer } from "@/lib/tools";
const emit = defineEmits(["nodata"]);
const page = inject("page");
// const runtimeConfig = useRuntimeConfig();
const props = defineProps({
  query: { type: Object, default: () => {} },
  format: { type: Object, default: () => {} },
  fields: { type: Array, default: () => [] },
  review: { type: Boolean, default: false },
  reviewOnly: { type: Boolean, default: false },
  preloaded: { type: Array, default: () => null },
  hydratedEmpty: { type: Boolean, default: false },
  truncateTitles: { type: Boolean, default: false },
});
const seeMore = ref("more");
const { fields, preloaded } = toRefs(props);
const isGgrid = props.fields.length === 1 && ["Logo", "Logo with Download"].includes(fields[0]);
const view = props?.format?.disptype || "list";
const myOffset = ref(0);
const variables = computed(() => {
  return {
    query: { ...queryFixer(props.query, page), offset: myOffset.value }, // props?.query, //
    fields: fields.value,
    hostname: unref(page).hostname, // || runtimeConfig.public.hostname,
    review: props?.review ? !!props.review : false,
    format: props?.format,
    reviewOnly: props?.reviewOnly ? !!props.reviewOnly : false,
    truncateTitles: props.truncateTitles,
  };
});
if (props.hydratedEmpty) emit("nodata");
const changeNickOnalaBrand = val => {
  if (unref(page).portal) return val;
  if (!val || !Array.isArray(val) || val.length < 1) return val;
  const tmp = _.cloneDeep(val);
  return tmp.map(e => {
    if (e?.Logo?.software && !e?.Logo?.downloadNick) e.Logo.downloadNick = unref(page).defaultNick;
    return e;
  });
};
const result = ref(_.cloneDeep(changeNickOnalaBrand(preloaded.value)));
if (!props.hydratedEmpty && !preloaded.value) {
  variables.value.query.limit = unref(variables).query.limit + 1;
  const { data } = await useAsyncQuery(UziQuery, variables.value);
  if (!unref(data)?.UziQuery?.length) {
    seeMore.value = "none";
    emit("nodata");
  } else {
    const tmp = _.cloneDeep(unref(data).UziQuery);
    result.value = changeNickOnalaBrand(tmp);
    if (props.query.limit >= tmp?.length) seeMore.value = "none";
    else tmp.splice(-1);
  } // resultFixer(data, variables);
} else variables.value.query.limit = unref(variables).query.limit + 1;

const showMore = () => {
  let slice = true;
  myOffset.value +=
    props.query.limit !== unref(variables).query.limit - 1 ? unref(variables).query.limit - 1 : props.query.limit;
  variables.value.query.limit = unref(variables).query.limit < 6 ? 7 : unref(variables).query.limit + 1;

  setTimeout(async () => {
    const { data: showMoreData } = await useAsyncQuery(UziQuery, variables.value);
    if (unref(showMoreData)?.UziQuery?.length === 0) {
      seeMore.value = "less";
      return;
    } else if (unref(showMoreData)?.UziQuery?.length < unref(variables).query.limit) {
      seeMore.value = "less";
      slice = false;
    }
    if (unref(showMoreData)?.UziQuery) unref(result).push(...unref(showMoreData).UziQuery);
    // unref(showMoreData).UziQuery.forEach(element => {
    //   unref(result).push(element);
    // });
    if (slice) unref(result).splice(-1);
  }, 500);
};
const showLess = () => {
  myOffset.value = 0;
  seeMore.value = "more";
  result.value.splice(props.query.limit);
  variables.value.query.limit = props.query.limit + 1;
};
</script>
